import Vue from 'vue'
import Router from 'vue-router'
import MakeCollection from "../components/admin/collection/MakeCollection";

function guardVendorRoutes(to, from, next) {

  let userType = JSON.parse(localStorage.getItem("user_detail")).user_type;
  if (userType == 'vendor') {
    if (!to.path.includes('vendor')) {
      if (!to.path.includes('dashboard') || !to.path.includes('products') || !to.path.includes('sales') || !to.path.includes('order-by-phone'))
        next('/admin/vendor/dashboard');

      let tempHref = to.path.replace('/admin/', '/admin/vendor/');
      next(tempHref);
    } else next();
  } else if (userType == 'admin') {
    if (to.path.includes('vendor')) {
      let tempHref = to.path.replace('/admin/vendor/', '/admin/');
      next(tempHref);
    } else next();
  } else next();
}

function adminGuard(to, from, next) {
  if (isAuthenticated()){
    if (hasRole('admin')){
      next()
    }else{
      next('/admin/vendor/dashboard')
    }
  }else{
    console.log('not authenticated')
    next({name: 'Login'})
  }
}

function isAuthenticated(){
  let item = localStorage.getItem('user_detail')
  return !!item && !!(JSON.parse(item)) && (JSON.parse(item).user_type == 'admin' || JSON.parse(item).user_type == 'vendor')
}

function hasRole(role){
  let user = JSON.parse(localStorage.getItem("user_detail"));
  return !(!user || user.user_type != role);
}

const AdminLayout = () => import('../components/admin/modules/AdminLayout')
const Dashboard = () => import('../components/admin/Dashboard')
const SiteSetting = () => import('../components/admin/SiteSetting')

// Users
const Users = () => import('../components/admin/users/Users')
const User = () => import('../components/admin/users/User')
const EditUser = () => import('../components/admin/users/EditUser')
const CreateUser = () => import('../components/admin/users/CreateUser')

// Views - Pages
const Register = () => import('../components/admin/pages/Register')
const PasswordReset = () => import('../components/admin/pages/PasswordReset')
const PasswordResetToken = () => import('../components/admin/pages/PasswordResetToken')
const Login = () => import('../components/admin/pages/Login')
const Page404 = () => import('../components/admin/pages/Page404')
const Page500 = () => import('../components/admin/pages/Page500')

//Regions
const Regions = () => import('../components/admin/region/Regions')
const EditRegion = () => import('../components/admin/region/EditRegion')
const CreateRegion = () => import('../components/admin/region/CreateRegion')

// Branches
const Branches = () => import('../components/admin/branch/Branches')
const EditBranch = () => import('../components/admin/branch/EditBranch')
const CreateBranch = () => import('../components/admin/branch/CreateBranch')

// Price Lists
const PriceLists = () => import('../components/admin/price-list/PriceLists')
const EditPriceList = () => import('../components/admin/price-list/EditPriceList')
const CreatePriceList = () => import('../components/admin/price-list/CreatePriceList')

// Products
const Products = () => import('../components/admin/product/Products')
const EditProduct = () => import('../components/admin/product/EditProduct')
const CreateProduct = () => import('../components/admin/product/CreateProduct')

// Customers
const Customers = () => import('../components/admin/customer/Customers')
const EditCustomer = () => import('../components/admin/customer/EditCustomer')
const CreateCustomer = () => import('../components/admin/customer/CreateCustomer')

// Orders
const Orders = () => import('../components/admin/order/Orders')
const CreateOrder = () => import('../components/admin/order/CreateOrder')
const BulkOrder = () => import('../components/admin/order/BulkOrder')
const ViewBulkOrder = () => import('../components/admin/order/BulkOrders')
const PrintBulkOrders = () => import('../components/admin/order/PrintBulkOrders')
const ViewOrder = () => import('../components/admin/order/ViewOrder')

//Notices
const Notices = () => import('../components/admin/notice/Notices')
const CreateNotice = () => import('../components/admin/notice/CreateNotice')
const Notice = () => import('../components/admin/notice/Notice')

// Collections
const Collections = () => import('../components/admin/collection/Collections')
// const CreateCollection = () => import('../components/admin/collection/MakeCollection')
const ViewCollection = () => import('../components/admin/collection/ViewCollection')

// Collections
const Comments = () => import('../components/admin/comment/Comments')

// Tickets
const Tickets = () => import('../components/admin/ticket/Tickets')
const CreateTicket = () => import('../components/admin/ticket/CreateTicket')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/admin',
      // redirect: '/admin/dashboard',
      name: 'Admin',
      component: AdminLayout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'settings',
          name: 'SiteSetting',
          beforeEnter: adminGuard,
          component: SiteSetting,
        },        
        {
          path: 'users',
          meta: { label: 'Users' },
          component: {
            render(c) { return c('router-view') }
          },
          beforeEnter: adminGuard,
          children: [
            {
              path: '/',
              name: 'Users',
              component: Users,
            },
            {
              path: 'create',
              meta: { label: 'Create User' },
              name: 'Create User',
              component: CreateUser
            },
            {
              path: ':id',
              meta: { label: 'User Details' },
              name: 'User',
              component: User,
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit User' },
              name: 'Edit User',
              component: EditUser
            }
          ]
        },
        {
          path: 'regions',
          meta: { label: 'Regions' },
          component: {
            render(c) { return c('router-view') }
          },
          beforeEnter: adminGuard,
          children: [
            {
              path: '/',
              name: 'Regions',
              component: Regions,
            },
            {
              path: 'create',
              meta: { label: 'Create Region' },
              name: 'Create Region',
              component: CreateRegion
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Region' },
              name: 'Edit Region',
              component: EditRegion
            }
          ]
        },
        {
          path: 'branches',
          meta: { label: 'Branches' },
          beforeEnter: adminGuard,
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Branches',
              component: Branches,
            },
            {
              path: 'create',
              meta: { label: 'Create Branch' },
              name: 'Create Branch',
              component: CreateBranch
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Branch' },
              name: 'Edit Branch',
              component: EditBranch
            }
          ]
        },
        {
          path: 'price-lists',
          meta: { label: 'PriceLists' },
          beforeEnter: adminGuard,
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'PriceLists',
              component: PriceLists,
            },
            {
              path: 'create',
              meta: { label: 'Create Price List' },
              name: 'Create Price List',
              component: CreatePriceList
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Price List' },
              name: 'Edit Price List',
              component: EditPriceList
            }
          ]
        },
        {
          path: 'products',
          meta: { label: 'Products' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Products',
              component: Products,
            },
            {
              path: 'create',
              meta: { label: 'Create Product' },
              name: 'Create Product',
              component: CreateProduct
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Product' },
              name: 'Edit Product',
              component: EditProduct
            }
          ]
        },
        {
          path: 'customers',
          meta: { label: 'Customers' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Customers',
              component: Customers,
            },
            {
              path: 'create',
              meta: { label: 'Create Customer' },
              name: 'Create Customer',
              component: CreateCustomer
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Customer' },
              name: 'Edit Customer',
              component: EditCustomer
            }
          ]
        },
        {
          path: 'orders',
          meta: { label: 'Orders' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Orders',
              component: Orders,
            },
            {
              path: 'create',
              meta: { label: 'Create Order' },
              name: 'Create Order',
              component: CreateOrder
            },
            {
              path: 'bulk/create',
              meta: { label: 'Bulk Order' },
              name: 'Bulk Order',
              component: BulkOrder
            },
            {
              path: 'bulk/view',
              meta: { label: 'Bulk Orders' },
              name: 'Bulk Orders',
              component: ViewBulkOrder
            },
            {
              path: 'bulk/print/:id',
              meta: { label: 'Print Bulk Order' },
              name: 'Print Bulk Order',
              component: PrintBulkOrders
            },
            {
              path: ':id/details',
              meta: { label: 'View Order' },
              name: 'View Order',
              component: ViewOrder
            },
          ]
        },
        {
          path: 'notices',
          meta: { label: 'Notices' },
          beforeEnter: adminGuard,
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Notices',
              component: Notices,
            },
            {
              path: 'create',
              meta: { label: 'Create Notice' },
              name: 'Create Notice',
              component: CreateNotice
            },
            {
              path: ':id/details',
              meta: { label: 'Notice Details' },
              name: 'Notice',
              component: Notice,
            },
          ]
        },
        {
          path: 'collections',
          meta: { label: 'Collections' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Collections',
              component: Collections,
            },
            {
              path: ':id/details',
              meta: { label: 'View Collection' },
              name: 'View Collection',
              component: ViewCollection
            }
          ]
        },
        {
          path: 'comments',
          meta: { label: 'Comments' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Comments',
              component: Comments,
            }
          ]
        },
        {
          path: 'tickets',
          meta: { label: 'Tickets' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              name: 'Tickets',
              component: Tickets,
            },
            {
              path: 'create',
              meta: { label: 'Create Ticket' },
              name: 'Create Ticket',
              component: CreateTicket
            }
          ]
        },
      ]
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/password/reset',
      name: 'PasswordReset',
      component: PasswordReset,
    },
    {
      path: '/password/reset/:token',
      name: 'PasswordResetToken',
      component: PasswordResetToken,
    },
    {
      path: '/admin/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '*',
      name: '404',
      component: Page404
    }
  ]
}
